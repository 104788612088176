'use strict'

var ModuleGeneral = function ModuleGeneral(){}

import riot from 'riot';

$(function() {

  /*
  ** riot
  */
  require('../../../views/partial/header-tpl.tag');
  require('../../../views/partial/header-card-back-tpl.tag');
  require('../../../views/partial/header-has-card-tpl.tag');
  require('../../../views/partial/header-logout-tpl.tag');
  require('../../../views/partial/footer-tpl.tag');
  require('../../../views/partial/footer-maintenance-tpl.tag');
  require('../../../views/partial/sidebar-tpl.tag');
  riot.mount('*');

  /*
  ** object fit for IE
  */
  var objectFitImages = require('object-fit-images');
  objectFitImages('img.obj_img');

  /**
  ** UserAgent
  */
  var ua = window.navigator.userAgent.toLowerCase();
  var is_ios = /iP(hone|(o|a)d)/.test(navigator.userAgent);
  var is_ip5 = false;
  if (matchMedia('(max-width: 320px)').matches) {
    is_ip5 = true;
  };
  var is_safari;
  if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1){
    is_safari = true;
  } else {
    is_safari = false;
  };

  //スムーススクロール
  // var urlHash = location.hash;
  // if(urlHash) {
  //   $('body,html').stop().scrollTop(0);
  //   setTimeout(function(){
  //     var target = $(urlHash);
  //     var position = target.offset().top;
  //     $('body,html').stop().animate({scrollTop:position}, 300);
  //   }, 100);
  // };
  $('a[href^="#"]').on('click',function() {
     var href= $(this).attr("href");
     var target = $(href == "#" || href == "" ? 'html' : href);
     var position = target.offset().top;
     $('body,html').animate({scrollTop:position}, 300, 'swing');
     return false;
  });

  $('a.anchor').on('click', function(e){
    var href = $(this).attr('href');
    if (!href) {
      return;
    }
    e.preventDefault();
    if (/#/.test($(this).attr('href'))) {
      $('body,html').animate({ scrollTop: ($($(this).attr("href")).position()||{top:0}).top }, 200,'swing');
    } else {
      location.href = $(this).attr('href');
    }
  });

  /*
  ** header 閉じるボタン
  */
  $('header .img_login img').on('click', function() {
    $('.top_login_form').toggleClass('hide');
    var src = $(this).attr('src');

    if(src.indexOf('login') != -1) {
      if(src.indexOf('sp') != -1) {
        $(this).attr('src','image/top/btn_close_sp.png');
      } else {
        $(this).attr('src','image/top/btn_close.png');
      }
    } else {
      if(src.indexOf('sp') != -1) {
        $(this).attr('src','image/top/btn_login_l_sp.png');
      } else {
        $(this).attr('src','image/top/btn_login_l.png');
      }
    }
  })

  /*
  **トップへ戻るボタン
  */
  var btn_top = $('.btn_top');
  $(window).on('scroll', function() {
    if ($(this).scrollTop() > 100) {
      btn_top.addClass('show_btn');
    } else {
      btn_top.removeClass('show_btn');
    }
  });
  btn_top.on('click', function() {
    $('body,html').animate({
      scrollTop: 0
    }, 300);
    return false;
  });
  if(is_ios) {
    btn_top.addClass('ios');
  }

  /*
  ** FAQ accordion
  */
  $('.accordion .q').on('click', function() {
    $(this).toggleClass('active');
    $(this).next('.a').slideToggle();
  });

  /*
  ** 汎用 accordion
  */
  $('.toggle').on('click', function() {
    $(this).toggleClass('active');
    $(this).next('.toggle_detail').slideToggle();
  });

  /*
  ** Modal
  */
  $('.modal_open').on('click', function(e) {
    var target = $(this).data('modal');
    $('.' + target).fadeIn();
    $('.modal_overlay').fadeIn();
  });

  $('.modal_close').on('click', function() {
    $(this).closest('.modal').fadeOut();
    $('.modal, .modal_overlay').fadeOut();
  });

  $('.modal_overlay').on('click', function() {
    $('.modal, .modal_overlay').fadeOut();
  });

  $('.modal_open_continue').on('click', function() {
    //モーダル後のモーダルを開く際。
    $(this).closest('.modal').fadeOut();
    var target = $(this).data('modal');
    setTimeout( function() {
      $('.' + target).fadeIn();
    }, 300);
  });

  /*
  ** register
  */
  $('.auth_code input').on('input', (e) => {
    const pattern = /[0-9]/gi;
    const val = String($(e.target).val());
    if (val.match(pattern) == null) {
      $(e.target).val('');
    } else {
      $(e.target).blur();
      for (let i = 0; i < $('.auth_code input').length; i++) {
        if ($('.auth_code input').eq(i).val() == '') {
          $('.auth_code input').eq(i).focus();
          break;
        }
      }
    }
  });

  //PHP Error return
  if($('.alert_msg').text() != "") {
    $('html,body').animate({ scrollTop: $(".alert_msg").offset().top }, '0');
  }

});

export default ModuleGeneral;